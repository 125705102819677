<template>
  <div>
    <div class="container bg-white mt-3">
      <div class="p-5">
        <ul class="row">
          <li class="col-3" v-for="item in itemList?.children" :key="item.id">
            <h3 class="fs-5 mb-4">{{ item.name }}</h3>
            <ul>
              <li v-for="child in item.children" :key="child.id" class="mb-2">
                <MenuCard
                  :id="child.id"
                  :title="child.name"
                  :img="child.imagePath"
                  @edit="editMenu({ ...child, categoryName: item.name })"
                />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <AppModal v-model="showModal" refName="MenuModal" title="編輯選單">
      <MenuItemsModify
        :categoryId="target.categoryId"
        :item="target.item"
        @success="modifySuccess"
      />
    </AppModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { SOLUTION } from "@/constants/article";
import MenuCard from "@/components/MenuCard";
import MenuItemsModify from "../../components/MenuItemsModify.vue";

export default {
  components: { MenuCard, MenuItemsModify },
  data() {
    return {
      target: {
        categoryId: null,
        item: null,
      },
      showModal: false,
    };
  },
  computed: {
    ...mapGetters("menu", ["menuList"]),
    ...mapGetters("category", ["getCategory"]),
    itemList() {
      return this.menuList.find((menu) => menu.type === SOLUTION.key && menu.level === 1);
    },
    categoryList() {
      return this.getCategory(SOLUTION.key);
    },
    categoryId: function () {
      return (name) => {
        return this.categoryList.find((c) => c.name === name)?.id;
      };
    },
  },
  methods: {
    ...mapActions("category", ["queryCategory"]),
    ...mapActions("menu", ["queryMenu"]),
    editMenu(item) {
      const { categoryName, ...rest } = item;
      this.target = {
        categoryId: this.categoryId(categoryName),
        item: rest,
      };
      this.showModal = true;
    },
    modifySuccess() {
      this.showModal = false;
      this.target = {
        categoryId: null,
        item: null,
      };
      this.queryMenu();
    },
  },
  created() {
    if (this.categoryList.length === 0) {
      this.queryCategory({ type: SOLUTION.key });
    }
  },
};
</script>
