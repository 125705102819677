<template>
  <VForm ref="form" v-slot="{ errors }" @submit="editMenuItem">
    <div class="mb-3 mb-lg-5">
      <label for="name" class="form-label">名稱<span class="text-primary">*</span></label>
      <VField
        id="name"
        name="名稱"
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors['名稱'] }"
        placeholder="請輸入名稱"
        rules="required"
        v-model="temp.name"
      />
      <ErrorMessage name="名稱" class="invalid-feedback" />
    </div>

    <div class="mb-5 col-12">
      <div class="mb-1">
        <div class="d-flex align-items-center">
          <p class="form-label me-2">網址</p>
          <a
            href="#"
            class="fs-8 text-primary"
            @click.prevent="
              temp.url = item.url;
              tempUrl = '';
            "
            >回到預設</a
          >
        </div>
        <ul class="mb-3">
          <li class="me-3 form-check">
            <input
              class="form-check-input cursor-pointer"
              type="radio"
              name="typeOther"
              id="customUrl"
              :value="1"
              v-model="type"
              disabled
            />
            <label class="form-check-label cursor-pointer fw-light" for="customUrl">
              自訂網址
            </label>
          </li>
          <li class="d-flex align-items-center me-3 mb-1">
            <div class="form-check" style="width: 140px">
              <input
                class="form-check-input cursor-pointer"
                type="radio"
                name="typeOther"
                id="articleUrl"
                :value="2"
                v-model="type"
              />
              <label class="form-check-label cursor-pointer fw-light" for="articleUrl">
                後台文章
              </label>
            </div>
            <select
              v-show="type === 2"
              v-model="tempUrl"
              @change="changeUrl"
              class="form-select"
              aria-label=".form-select"
            >
              <option value="">請選擇</option>
              <option
                v-for="artitcle in artitcles"
                :key="artitcle.articleUuid"
                :value="artitcle.articleUuid"
              >
                {{ artitcle.categoryName }} | {{ artitcle.title }}
              </option>
            </select>
          </li>
        </ul>
      </div>
      <div>
        <div class="d-flex align-items-center">
          <VField
            id="uri"
            name="網址"
            type="text"
            class="form-control me-3"
            :class="{ 'is-invalid': errors['網址'] }"
            placeholder="請輸入網址"
            rules="required"
            v-model="temp.url"
            :disabled="type === 2"
          />
          <a :href="temp.url" target="_blank">
            <i class="bi bi-box-arrow-up-right" style="top: -3px"></i>
          </a>
        </div>
        <ErrorMessage name="網址" class="invalid-feedback" />
      </div>
    </div>
    <button type="submit" class="btn btn-primary">儲存</button>
  </VForm>
</template>

<script>
import { apiQueryUserArticle } from "@/api/article";
import { apiUpdateMenuItem } from "@/api/menu";
import { SOLUTION } from "@/constants/article";
export default {
  props: {
    categoryId: Number,
    item: Object,
  },
  data() {
    return {
      temp: {},
      type: 2,
      artitcles: [],
      tempUrl: "",
    };
  },
  methods: {
    async getArticle({ categoryId }) {
      try {
        const res = await apiQueryUserArticle({ type: SOLUTION.key, categoryId });
        const { data, code, message } = res.data;
        if (code === 200) {
          const { content } = data;
          this.artitcles = content;
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        // regardless
      }
    },
    changeUrl(e) {
      this.temp.url = `/solution-service/${e.target.value}`;
    },
    async editMenuItem() {
      this.$vLoading(true);
      try {
        const { id, name, url } = this.temp;
        await apiUpdateMenuItem(id, { name, url });
        this.temp = {};
        this.type = 1;
        this.tempUrl = "";
        this.$refs.form.resetForm();
        this.$emit("success");
      } catch (error) {
        // regarless
      } finally {
        this.$vLoading(false);
      }
    },
  },
  watch: {
    item(val) {
      this.temp = { ...val };
    },
    categoryId(val) {
      this.getArticle({ categoryId: val });
    },
  },
};
</script>
