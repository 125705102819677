<template>
  <div class="d-flex align-items-center justify-content-between border p-2 bg-gray-100">
    <div class="d-flex align-items-center">
      <img :src="img && $imageFilters(img)" alt="icon" style="width: 20px" class="me-2" />
      <p>{{ title }}</p>
    </div>
    <button
      class="btn btn-sm btn-outline-primary rounded-circle fs-8 m-1"
      type="button"
      @click="$emit('edit')"
    >
      <i class="bi bi-pencil-fill" style="top: -3px"></i>
    </button>
  </div>
</template>

<script>
// 目前只設計編輯Solution
export default {
  props: {
    id: [String, Number],
    title: String,
    img: String,
  },
  data() {
    return {
      isEdit: false,
    };
  },
};
</script>
